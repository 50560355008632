import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import getLPTheme from './getLPTheme';
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Alert } from '@mui/material';

export default function SignIn() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const [error, setError] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const signIn = useSignIn();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get('email').length === 0) {
      setError("Please enter an email & password.");
      setShowAlert(true);
      return;
    } else if (data.get('password').length === 0) {
      setError("Please enter an email & password.");
      setShowAlert(true);
      return;
    }
    setShowAlert(false);
    axios.post(process.env.REACT_APP_API_URL + '/api/sign-in', {
      email: data.get('email'),
      password: data.get('password'),
    })
      .then(function (response) {
        setError(response.data.error || "");
        if (!error) {
          if (signIn({
            auth: {
              token: response.data.token // Replace with your token
            },
            refresh: response.data.refreshToken,
            userState: {
              name: response.data.firstName,
              uid: response.data.uid,
              email: response.data.email,
              companyId: response.data.companyId,
              typeId: response.data.typeId
            }
          })) {
            navigate('/');
          } else {
            setError("Err 2: Somthing went wrong. Please contact Support.");
            setShowAlert(true);
          }
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          setError("Email or password is incorrect.");
        }
        if (error.response && error.response.status === 404) {
          setError("Could not connect to server. Try again later.");
        }
        if (error.response && error.response.status === 500) {
          setError("Could not connect to server.Try again later.");
        }
        else{
          // console.log(error)
        }
        setShowAlert(true);
      });
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{
        backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)',
        backgroundSize: '100% 200px',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 }
      }}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item>
                  <Link color="inherit" href="/sign-up" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Alert
                variant="outlined"
                severity="error"
                sx={{ marginTop: 2, visibility: showAlert ? 'visible' : 'hidden' }}
              >
                {error && error}
              </Alert>
            </Box>
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
