import axios from "axios";
import createRefresh from "react-auth-kit/createRefresh";

const refreshApi = process.env.REACT_APP_API_URL + "/api/refresh-token";

const refresh = createRefresh({
  interval: 10, // Refresh every 10 mins
  refreshApiCallback: async (param) => {
    console.log("Starting token refresh...");

    try {
      const response = await axios.post(
        refreshApi,
        { refreshToken: param.refreshToken },
        {
          headers: { Authorization: `Bearer ${param.refreshToken}` },
        }
      );

      return {
        isSuccess: true,
        newAuthToken: response.data.access_token,
        newAuthTokenExpireIn: 15, // Assuming the token expires in 15 mins (adjust as needed)
        newRefreshTokenExpiresIn: 40000, // Assuming the refresh token expires in 1 month (adjust as needed)
      };
    } catch (error) {
      console.error("Error refreshing token:", error);
      return {
        isSuccess: false,
      };
    }
  },
});

export { refresh };
