import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import getLPTheme from './getLPTheme';
import ManageProfile from '../components/ManageProfile';

export default function Profile() {
  const [mode, setMode] = useState('light');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box
        sx={{
          backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)',
          backgroundSize: '100% 200px',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                component='h2'
                variant='h2'
                sx={{
                  width: { sm: "100%", md: "100%" },
                  textAlign: "center",
                  paddingBottom: 4
                }}
              >
                Your Profile
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper sx={{ width: "100%", padding: "20px" }}>
                <ManageProfile />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ width: "100%", padding: "20px", mb: 3 }}>
                <Typography>Upcoming Meetings will be here</Typography>
              </Paper>
              <Paper sx={{ width: "100%", padding: "20px" }}>
                <Typography>Company Users and details will be here</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}