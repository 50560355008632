// Import necessary modules and components
import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import getLPTheme from './getLPTheme';
import { Divider } from '@mui/material';
import EventCalendar from '../components/Calendar/EventCalendar'
import BasicSelect from "../components/BasicSelect"
import Stack from '@mui/material/Stack';

export default function Rooms() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const [roomsList, setRoomsList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [roomName, setRoomName] = useState('');
  const [roomID, setRoomID] = useState('');

  // API endpoints
  const getRoomsApi = process.env.REACT_APP_API_URL+'/api/get-rooms-list';
  const getLocationsApi = process.env.REACT_APP_API_URL+'/api/get-locations';

  // Fetch rooms from the API
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get(getRoomsApi);
        setRoomsList(response.data);
        //console.log('Rooms API Response:', response.data);
      } catch (error) {
        console.error('Error fetching Rooms:', error);
        // Handle error, show error message, etc.
      }
    };

    fetchRooms();
  }, [getRoomsApi]);

  // Fetch locations from the API
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(getLocationsApi);
        setLocationsList(response.data);
        //console.log('Locations API Response:', response.data);
      } catch (error) {
        console.error('Error fetching locations:', error);
        // Handle error, show error message, etc.
      }
    };

    fetchLocations();
  }, [getLocationsApi]);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{
        backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)', backgroundSize: '100% 200px',
        backgroundRepeat: 'no-repeat', display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 }
      }}>
        <Divider />
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          textAlign="center"
          alignSelf="center"
          spacing={1.5}
          useFlexGap
          sx={{ pt: 2, width: { xs: 'auto', sm: 'auto' } }}
          marginBottom={5}
        >
          <BasicSelect
            label="Location"
            itemList={locationsList.map((location) => ({
              value: location.lid,
              label: location.name, // Add showWhen condition
            }))}
            selectedValue={selectedLocation}
            onValueChange={(value) => setSelectedLocation(value)}
          />
          <BasicSelect
            label="Room"
            itemList={roomsList.map((room) => ({
              value: room.room_id,
              label: `${room.name} (Capacity: ${room.cap})`,
              showWhen: room.lid === selectedLocation, // Add showWhen condition
            }))}
            selectedValue={selectedRoom}
            onValueChange={(value) => {
              setSelectedRoom(value);
              // Set room name based on selected room
              const selectedRoomData = roomsList.find((room) => room.room_id === value);
              if (selectedRoomData) {
                // console.log(selectedRoomData) // Debugging ST
                setRoomName(selectedRoomData.name);
                setRoomID(selectedRoomData.room_id);
              }
            }}
          />
        </Stack>
        {selectedRoom && (
          <EventCalendar key={selectedRoom} roomName={roomName} roomid={roomID} />
        )}
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
