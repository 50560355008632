import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const items = [
  {
    image: "/static/images/image-list/Manju_Bhatia.webp",
    name: "MANJU BHATIA",
    title: "CO-FOUNDER, CHIEF EXECUTIVE OFFICER",
    description:
      "Mrs. Manju Bhatia has more than 25 years of experience in the field of real estate, servicing, logistic management, education and much more. Mrs. Bhatia has helped setting up Prime Location Real Estate in UAE. After the success she moved to SIRVA Worldwide Relocation & Moving where she was a key part in structuring the logistic alliances. Mrs. Bhatia started her own entrepreneur journey with Mr Gandhi 5 years ago where she is spearheading all the operation and serving.",
  },
  {
    image: "/static/images/image-list/Jayesh_Gandhi.webp",
    name: "JAYESH GANDHI",
    title: "FOUNDER, MANAGING DIRECTOR",
    description:
      "Mr. Jayesh R. Gandhi was born 1967 in Mumbai in an esteemed business family that has had a history of business interests and social responsibility behind it. Mr. Gandhi is a passionate serial entrepreneur with more than 3 decades of experience in real estate, automobile, import-export, dealership and much more.",
  },
];

export default function About() {
  return (
    <Box
      id='about'
      sx={{ pb: 8 }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          variant='h6'
          textAlign={"center"}
        >
          Millennial Pod is an innovative fully serviced workspace where
          companies & people grow together. We have co-working & office space
          solutions for all types of business’ & professionals. We believe in
          the thriving effect of a feel-good workplace which is why we keep open
          spaces for you to unwind and recharge your batteries. Cozy areas with
          sofas and lounge seatings invite you to have a creative break. In our
          library you can retreat and read. Our event space offers several
          options for your body and mind to come out to play and meet and mingle
          with like-minded co-workers. We organise regular events for our
          members and their friends as an opportunity to come together to
          connect and learn from each other.
        </Typography>
        {items.map((item, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            direction={{ xs: "column", sm: "row" }}
            alignItems={"center"}
            mt={8}
          >
            <Grid
              item
              xs={4}
              md={4}
            >
              <img
                src={item.image}
                alt='Millennial Pod Founders'
                style={{
                  width: "250px",
                  borderRadius: "50%",
                  boxShadow: "0px 0px 8px 4px rgba(203, 224, 15, 0.2)",
                }}
              />
            </Grid>
            <Grid
              item
              xs={8}
              md={8}
            >
              <Typography variant='h4'>{item.name}</Typography>
              <Typography variant='h5'>{item.title}</Typography>
              <Typography variant='h6'>{item.description}</Typography>
            </Grid>
          </Grid>
        ))}
      </Container>
    </Box>
  );
}
